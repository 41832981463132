/**
 * @module
 **/
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import DetailItemsList from "../../details/DetailItemsList.js"
import {getWKTParser} from "../../util/getWKTParser.js"
import KomKodeliste from "../datafordeler/KommuneKodeliste.js"
import DataApiFetcher from "../data-api/Fetcher.js"
/**
 * Viser info for et matrikelnummer fra GeoSearch
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
 * _type: Septima.Search.GeoSearch
 * _options:
 *   kommunekode: '101'
 * detailhandlers:
 *   - _type: Septima.Search.JordStykkeInfoProvider
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 *geoSearcher.addDetailHandlerDef(new Septima.Search.JordStykkeInfoProvider())
 *
 * @example <caption>ES6:</caption>
 * import JordStykkeInfoProvider from './searchers/detailhandlers/JordStykkeInfoProvider.js'
 * geoSearcher.addDetailHandlerDef(new JordStykkeInfoProvider())
 * @api
 **/
export default class JordStykkeInfoProvider extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.fields]=['kommune_result','postdistrikt_result'] Felter, der skal medtages i info. Mulige felter er "presentationString","elavsnavn", "elavskode", "sfe_nummer", 'matrnr', 'centroid_x', 'centroid_y', 'retskreds_result','opstillingskreds_result','politikreds_result','kommune_result','postdistrikt_result', 'arealer'
   * @param {Object} options.ejdSearcher datafordeler.ejdSearcher
   **/
  constructor(options={}) {
    let defaultOptions = {
      buttonText: "Om jordstykket",
      isApplicable: (result) => {
        return (result.source === "Kortforsyningen" && (result.type.id === "matrikelnumre"))
      }
    }
    super(Object.assign(defaultOptions, options))
    
    this.handlerFunction = this.handler
    
    //Default fields
    this.fields = ['adminfo', 'ejendom', 'arealer', 'sekundaer']
    if (options.fields != null) {
      if (options.fields[0] === '*')
        this.fields = ["adminfo", "ejendom", "elavsnavn", "elavskode", "sfe_nummer", 'matrnr', 'centroid_x', 'centroid_y', 'retskreds_result','opstillingskreds_result','politikreds_result','kommune_result','postdistrikt_result', 'arealer', 'sekundaer']
      else
        this.fields = options.fields
    }

    if (options.ejdSearcher)
      this.ejdSearcher = options.ejdSearcher
    else 
      throw 'JordStykkeInfoProvider must have option ejdSearcher (datafordeler.EjendomsSearcher)'
    
    this.dafFetcher = this.ejdSearcher.fetcher
    this.dataApiFetcher = new DataApiFetcher()
    
    this.komKodeliste = new KomKodeliste()

  }

  async handler(result) {
    let listHeader = "Matrikel nr. " +
      (result.data.matrikelnummer ? result.data.matrikelnummer : result.data.matrnr) + ", " +
      (result.data.ejerlavsnavn ? result.data.ejerlavsnavn : result.data.elavsnavn)
    let detailItemsList = new DetailItemsList({
      header: listHeader,
      name: "info"
    })
    let returnItems = []

    let wktParser = getWKTParser()
    let interiorPoint = wktParser.getInteriorPoint(result.geometry)

    for (let field of this.fields) {
      if (field === 'adminfo') {
        let items = await this.getAdmItems(result)
        for (let item of items)
          detailItemsList.append(item)
      } else {
        let item = await this.createItem(result, interiorPoint, field)
        if (typeof item !== 'undefined') {
          switch (field) {
            case 'arealer':
              returnItems.push(item)
              break
            case 'ejendom':
              returnItems.push(item)
              break
            case 'sekundaer':
              returnItems.push(item)
              break
            default:
              detailItemsList.append(item)  
          }
        }
      }
    }
    if (detailItemsList.items.length>0)
      returnItems.unshift(detailItemsList.asItem())
    
    return returnItems

  }
  
  async getAdmItems (result) {
    
    let dafFetcher = this.getDafFetcher("adminfo")
    if (dafFetcher) {
      let props = result.data
      let ejerlavskode = props.elavskode
      let matrikelnr = props.matrnr
      let dafJordstykke = await this.dafFetcher.read("matrikel", "matrikel", "jordstykke", {Ejerlavskode: ejerlavskode, Matrikelnr: encodeURIComponent(matrikelnr)}, this.getLogger())
      if (dafJordstykke) {
        let items = []
        let kommuneCode = dafJordstykke.properties.kommuneLokalId
        let kommuneText = this.komKodeliste.findKommune(dafJordstykke.properties.kommuneLokalId)
        items.push({
          type: "labelvalue",
          label: "Kommune",
          value: `${kommuneCode} (${kommuneText})`
        })
        let statusText = dafJordstykke.properties.status
        items.push({
          type: "labelvalue",
          label: "Status",
          value: statusText
        })
        let idText = dafJordstykke.properties.id_lokalId
        items.push({
          type: "labelvalue",
          label: "id",
          value: idText
        })        
        return items
      } else {
        return []
      }
    }
  }

  async getDetailItemForBfeNummer(bfeNummer, header = "Ejendom") {
    let bfeResult = await this.ejdSearcher.get(bfeNummer, "bfe")
    let detailItemsList = new DetailItemsList({
      header,
      name: "bfe_info"
    })
    if (bfeResult) {
      detailItemsList.append({
        type: "result",
        result: bfeResult
      })
    } else {
      detailItemsList.append({
        type: "labelvalue",
        label: "bfe " + bfeNummer,
        value: "blev ikke fundet"
      })
    }
    return detailItemsList.asItem()
  }

  getDafFetcher(field) {
    if (!this.dafFetcher) {
      // eslint-disable-next-line no-console
      if (console && console.warn) {
        try{
          // eslint-disable-next-line no-console
          console.warn("JordStykkeInfoProvider->" + field + " kræver adgang til datafordeleren (options.dafFetcher)")
          // eslint-disable-next-line no-empty
        } catch(e) {}
        return
      }
    }else{
      return this.dafFetcher
    }
  }
  
  async createItem(result, interiorPoint, field) {
    let props = result.data
    
    if (field === 'sekundaer') {
      let dafFetcher = this.getDafFetcher(field)
      if (dafFetcher) {
        let ejerlavskode = props.elavskode
        let matrikelnr = props.matrnr
        let dafJordstykke = await this.dafFetcher.read("matrikel", "matrikel", "jordstykke", {Ejerlavskode: ejerlavskode, Matrikelnr: encodeURIComponent(matrikelnr)}, this.getLogger())
        if (dafJordstykke)
          return await this.getSekundaerDetails(dafJordstykke)
      }
    }
    if (field === 'arealer') {
      let dafFetcher = this.getDafFetcher(field)
      if (dafFetcher) {
        let ejerlavskode = props.elavskode
        let matrikelnr = props.matrnr
        let dafJordstykke = await this.dafFetcher.read("matrikel", "matrikel", "jordstykke", {Ejerlavskode: ejerlavskode, Matrikelnr: encodeURIComponent(matrikelnr)}, this.getLogger())
        if (dafJordstykke)
          return await this.getArealDetails(dafJordstykke)
      }
    }
    
    if (field === 'ejendom') {
      let dafFetcher = this.getDafFetcher(field)
      if (dafFetcher) {
        let ejerlavskode = props.elavskode
        let matrikelnr = props.matrnr
        let sfeResponse = await this.dafFetcher.read("matrikel", "matrikel", "samletfastejendom", {Ejerlavskode: ejerlavskode, Matrikelnr: encodeURIComponent(matrikelnr)}, this.getLogger())
        let bfeNummer = sfeResponse.features[0].properties.BFEnummer
        if (bfeNummer)
          return await this.getDetailItemForBfeNummer(bfeNummer, "Samlet fast ejendom")
      }
    }

    if (field === 'retskreds_result') {
      let sqResults = await result.searcher.sq({target: {source: "Kortforsyningen", type: "retskredse"}, geometry: interiorPoint})
      let results = sqResults.getResults()
      if (results.length > 0)
        return {
          type: 'result',
          label: "Retskreds",
          result: results[0]
        }
    }
    if (field === 'opstillingskreds_result') {
      let sqResults = await result.searcher.sq({target: {source: "Kortforsyningen", type: "opstillingskredse"}, geometry: interiorPoint})
      let results = sqResults.getResults()
      if (results.length > 0)
        return {
          type: 'result',
          label: "Opstillingskreds",
          result: results[0]
        }
    }
    if (field === 'politikreds_result') {
      let sqResults = await result.searcher.sq({target: {source: "Kortforsyningen", type: "politikredse"}, geometry: interiorPoint})
      let results = sqResults.getResults()
      if (results.length > 0)
        return {
          type: 'result',
          label: "Politikreds",
          result: results[0]
        }
    }
    if (field === 'kommune_result') {
      let sqResults = await result.searcher.sq({target: {source: "Kortforsyningen", type: "kommuner"}, geometry: interiorPoint})
      let results = sqResults.getResults()
      if (results.length > 0)
        return {
          type: 'result',
          label: "Kommune",
          result: results[0]
        }
    }
    if (field === 'postdistrikt_result') {
      let sqResults = await result.searcher.sq({target: {source: "Kortforsyningen", type: "postdistrikter"}, geometry: interiorPoint})
      let results = sqResults.getResults() 
      if (results.length > 0)
        return {
          type: 'result',
          label: "Postdistrikt",
          result: results[0]
        }
    }
    if (field === 'sfe_nummer')
      return {
        type: 'labelvalue',
        label: "SFE-nummer",
        value: props.sfeejdnr
      }
    if (field === 'elavsnavn') 
      return {
        type: 'labelvalue',
        label: 'Ejerlav',
        value: props.elavsnavn
      }
    else if(field === 'elavskode') 
      return {
        type: 'labelvalue',
        label: 'Ejerlavskode',
        value: props.elavskode
      }
    else if(field === 'matrnr') 
      return {
        type: 'labelvalue',
        label: 'Matrikelnumer',
        value: props.matrnr
      }
    else if(field === 'presentationString') 
      return {
        type: 'labelvalue',
        label: 'Matrikel',
        value: props.presentationString
      }
    else if(field === 'centroid_x') 
      return {
        type: 'labelvalue',
        label: 'Centroide x koordinat',
        value: props.centroid_x
      }
    else if(field === 'centroid_y') 
      return {
        type: 'labelvalue',
        label: 'Centroide y koordinat',
        value: props.centroid_y
      }
    else if(field === 'landbrugsnotering') {
      let bfeEjendom = await this.dataApiFetcher.get("bestemtfastejendom", { bfenummer: `eq.${props.sfeejdnr}`, select: "landbrugsnotering" }) 
      let landbrugsnotering= bfeEjendom[0].landbrugsnotering ? bfeEjendom[0].landbrugsnotering : 'Ingen' 
      return {
        type: 'labelvalue',
        label: 'Landbrugsnotering',
        value: landbrugsnotering
      }
    }
  }

  getSekundaerDetails(fokusJordStykke) {
    let detailItemsList = new DetailItemsList({
      itemType: "labelvalue",
      header: "Andre oplysninger",
      name: "jordstykke_anden_info",
      infoItemsHeaders: [
        {type: "labelvalue", label: ""},
        {type: "labelvalue", label: "Areal (m2)", valueformat: "int"}]
    })

    let falleslodOplysning = fokusJordStykke.properties.faelleslod? "Ja" : "-"
    detailItemsList.append({
      type: "labelvalue",
      value: "Er fælleslod",
      infoItems: [
        {type: "labelvalue", value: falleslodOplysning},
        {type: "labelvalue", value: null}]
    })

    let strandbeskyttelsesOplysning = fokusJordStykke.properties.strandbeskyttelse.omfang ? fokusJordStykke.properties.strandbeskyttelse.omfang : "-"
    let strandbeskyttelsesAreal = fokusJordStykke.properties.strandbeskyttelse.strandbeskyttelsesareal ? fokusJordStykke.properties.strandbeskyttelse.strandbeskyttelsesareal : null
    detailItemsList.append({
      type: "labelvalue",
      value: "Strandbeskyttelse",
      infoItems: [
        {type: "labelvalue", value: strandbeskyttelsesOplysning},
        {type: "labelvalue", value: strandbeskyttelsesAreal, valueformat: "int"}]
    })

    let klitfredningsOplysning = fokusJordStykke.properties.klitfredning.omfang ? fokusJordStykke.properties.klitfredning.omfang : "-"
    let klitfredningsAreal = fokusJordStykke.properties.klitfredning.klitfredningsareal ? fokusJordStykke.properties.klitfredning.klitfredningsareal : null
    detailItemsList.append({
      type: "labelvalue",
      value: "Klitfredning",
      infoItems: [
        {type: "labelvalue", value: klitfredningsOplysning},
        {type: "labelvalue", value: klitfredningsAreal, valueformat: "int"}]
    })

    let staormfaldsOplysning = fokusJordStykke.properties.stormfald.length > 0 ? fokusJordStykke.properties.stormfald[0] : "-"
    detailItemsList.append({
      type: "labelvalue",
      value: "Stormfald",
      infoItems: [
        {type: "labelvalue", value: staormfaldsOplysning},
        {type: "labelvalue", value: null}]
    })

    let majoratskovsOplysning = "-"
    let majoratskovsAreal = null
    if (fokusJordStykke.properties.majoratskov) {
      majoratskovsOplysning = fokusJordStykke.properties.majoratskov.majoratskovsnummer ? fokusJordStykke.properties.majoratskov.majoratskovsnummer : "-"
      majoratskovsAreal = fokusJordStykke.properties.majoratskov.omfang ? fokusJordStykke.properties.majoratskov.omfang : null
    } else {
      majoratskovsOplysning = fokusJordStykke.properties.majoratsskov.majoratsskovsnummer ? fokusJordStykke.properties.majoratskov.majoratskovsnummer : "-"
      majoratskovsAreal = fokusJordStykke.properties.majoratsskov.omfang ? fokusJordStykke.properties.majoratskov.omfang : null
    }
    detailItemsList.append({
      type: "labelvalue",
      value: "Majoratskov",
      infoItems: [
        {type: "labelvalue", value: majoratskovsOplysning},
        {type: "labelvalue", value: majoratskovsAreal, valueformat: "int"}]
    })
    return detailItemsList.asItem()  
  }
  
  async getArealDetails(fokusJordStykke) {
    let detailItemsList = new DetailItemsList({
      itemType: "labelvalue",
      header: "Arealer",
      name: "arealer",
      infoItemsHeaders: [
        {type: "labelvalue", label: "Areal (m2)", valueformat: "int"},
        {type: "labelvalue", label: "Note"}]
    })
    let registreretAreal = fokusJordStykke.properties.registreretAreal ? fokusJordStykke.properties.registreretAreal : null
    let registreretArealNote = fokusJordStykke.properties.arealberegningsmetode ? fokusJordStykke.properties.arealberegningsmetode : ""
    detailItemsList.append({
      type: "labelvalue",
      label: "Type",
      value: "Registreret",
      infoItems: [
        {type: "labelvalue", value: registreretAreal, valueformat: "int"},
        {type: "labelvalue", value: registreretArealNote}]
    })
    let vejAreal = fokusJordStykke.properties.vejareal ? fokusJordStykke.properties.vejareal : null
    let vejArealNote = fokusJordStykke.properties.vejarealberegningsstatus ? fokusJordStykke.properties.vejarealberegningsstatus : ""
    detailItemsList.append({
      type: "labelvalue",
      label: "Type",
      value: "Vej",
      infoItems: [
        {type: "labelvalue", value: vejAreal, valueformat: "int"},
        {type: "labelvalue", value: vejArealNote}]
    })
    let fredskovAreal = (fokusJordStykke.properties.fredskov && fokusJordStykke.properties.fredskov.fredskovsareal) ? fokusJordStykke.properties.fredskov.fredskovsareal : null
    let fredskovArealNote
    if (fredskovAreal === null)
      fredskovArealNote = "(Ukendt)"
    else
      fredskovArealNote = (fokusJordStykke.properties.fredskov && fokusJordStykke.properties.fredskov.omfang) ? fokusJordStykke.properties.fredskov.omfang : ""
    detailItemsList.append({
      type: "labelvalue",
      label: "Type",
      value: "Fredskov",
      infoItems: [
        {type: "labelvalue", value: fredskovAreal, valueformat: "int"},
        {type: "labelvalue", value: fredskovArealNote}]
    })
    let vandarealinkluderingNote = fokusJordStykke.properties.vandarealinkludering ? fokusJordStykke.properties.vandarealinkludering : ""
    detailItemsList.append({
      type: "labelvalue",
      label: "Type",
      value: "Vand",
      infoItems: [
        {type: "labelvalue", value: null},
        {type: "labelvalue", value: vandarealinkluderingNote}]
    })

    return detailItemsList.asItem()
  }


}